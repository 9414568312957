<template>
  <div class="grid grid-cols-1 gap-6 sm:mt-16 sm:grid-cols-2 animated reveal">
    <div class="col-span-1 pt-8">
      <div class="text-4.5xl font-bold text-invest-primary">Get Started</div>
      <p class="mt-5 text-sm sm:text-base text-invest-primary text-opacity-80">
        Create and account and start investing.
      </p>
    </div>
    <div class="flex justify-end col-span-1 sm:-mt-16">
      <div class="card">
        <alert variant="error" v-if="registerForm.error">
          {{ registerForm.error }}
        </alert>
        <form @submit.prevent="submitForm" class="mt-4">
          <div class="grid grid-cols-1 gap-2 sm:grid-cols-2">
            <form-input
              height="h-16"
              :form="registerForm"
              name="first_name"
              v-model="registerForm.data.first_name.value"
            >
              First name
            </form-input>
            <form-input
              height="h-16"
              name="last_name"
              :form="registerForm"
              v-model="registerForm.data.last_name.value"
            >
              Last name
            </form-input>
          </div>
          <form-input
            height="h-16"
            type="email"
            name="email"
            :form="registerForm"
            v-model="registerForm.data.email.value"
          >
            Email Address
          </form-input>
          <form-input
            height="h-16"
            name="mobile"
            :form="registerForm"
            v-model="registerForm.data.mobile.value"
          >
            Phone number
          </form-input>
          <form-input
            height="h-16"
            type="password"
            name="password"
            :form="registerForm"
            v-model="registerForm.data.password.value"
          >
            Password
          </form-input>
          <div class="inline-flex items-center mt-4">
            <checkbox v-model="termsAndCondition">
              <div class="text-xs cursor-pointer text-invest-dark">
                I accept the
                <span class="font-semibold text-invest-purple">
                  terms and conditions
                </span>
              </div>
            </checkbox>
          </div>
          <div class="mt-12">
            <button
              class="btn btn-primary"
              type="submit"
              :disabled="disabledButton"
            >
              <loader v-if="registerForm.loading" />
              <span v-else>Sign Up</span>
            </button>
          </div>
          <div class="mt-8 text-xs text-center text-invest-dark">
            Already have an account?
            <span
              class="font-semibold cursor-pointer text-invest-purple"
              @click="navigateToLogin"
            >
              Sign In
            </span>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, SetupContext, ref, computed } from 'vue';
import validation from '@/modules/validation';
import resource from '@/modules/resource';
import authModule from '@/modules/auth/authModule';
import { registerRequest } from '@/modules/requests';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';
import { AUTH_TOKEN } from '@/constants';

export default defineComponent({
  emits: ['navigate'],
  setup: (_props, { emit }: SetupContext) => {
    const { registerForm } = authModule();
    const { dispatch } = useStore();
    const { push } = useRouter();

    const { mapFormErrors } = resource();

    const termsAndCondition = ref<boolean>(false);

    const emptyFields = computed(() => {
      for (let key in registerForm.value.data) {
        if (registerForm.value.data[key].value === '') {
          return false;
        }
      }
      return true;
    });

    const disabledButton = computed(() => {
      return (
        !emptyFields.value ||
        registerForm.value.loading ||
        !termsAndCondition.value
      );
    });

    const submitForm = () => {
      if (validation().validateForm(registerForm.value)) {
        registerForm.value.loading = true;
        registerRequest(
          registerForm.value.data.mobile.value,
          registerForm.value.data.email.value,
          registerForm.value.data.password.value,
          registerForm.value.data.first_name.value,
          registerForm.value.data.last_name.value,
        )
          .then(({ data }) => {
            const { token, user }: any = data;
            window.localStorage.setItem(AUTH_TOKEN, token);
            dispatch('login', { user, token }).then(() => push('/dashboard'));
          })
          .catch((error) => {
            mapFormErrors(registerForm.value, error?.response?.data?.errors);
            registerForm.value.error = error?.response?.data?.message;
          })
          .finally(() => (registerForm.value.loading = false));
      }
    };

    const navigateToLogin = () => emit('navigate', 'login');

    return {
      registerForm,
      navigateToLogin,
      submitForm,
      termsAndCondition,
      disabledButton,
    };
  },
});
</script>
