
import { defineComponent, SetupContext, ref, computed } from 'vue';
import validation from '@/modules/validation';
import resource from '@/modules/resource';
import authModule from '@/modules/auth/authModule';
import { registerRequest } from '@/modules/requests';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';
import { AUTH_TOKEN } from '@/constants';

export default defineComponent({
  emits: ['navigate'],
  setup: (_props, { emit }: SetupContext) => {
    const { registerForm } = authModule();
    const { dispatch } = useStore();
    const { push } = useRouter();

    const { mapFormErrors } = resource();

    const termsAndCondition = ref<boolean>(false);

    const emptyFields = computed(() => {
      for (let key in registerForm.value.data) {
        if (registerForm.value.data[key].value === '') {
          return false;
        }
      }
      return true;
    });

    const disabledButton = computed(() => {
      return (
        !emptyFields.value ||
        registerForm.value.loading ||
        !termsAndCondition.value
      );
    });

    const submitForm = () => {
      if (validation().validateForm(registerForm.value)) {
        registerForm.value.loading = true;
        registerRequest(
          registerForm.value.data.mobile.value,
          registerForm.value.data.email.value,
          registerForm.value.data.password.value,
          registerForm.value.data.first_name.value,
          registerForm.value.data.last_name.value,
        )
          .then(({ data }) => {
            const { token, user }: any = data;
            window.localStorage.setItem(AUTH_TOKEN, token);
            dispatch('login', { user, token }).then(() => push('/dashboard'));
          })
          .catch((error) => {
            mapFormErrors(registerForm.value, error?.response?.data?.errors);
            registerForm.value.error = error?.response?.data?.message;
          })
          .finally(() => (registerForm.value.loading = false));
      }
    };

    const navigateToLogin = () => emit('navigate', 'login');

    return {
      registerForm,
      navigateToLogin,
      submitForm,
      termsAndCondition,
      disabledButton,
    };
  },
});
